/* ===============================
=            Choices            =
=============================== */
.choices {
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    font-size: 16px;
}

.choices:focus {
    outline: none;
}

.choices:last-child {
    margin-bottom: 0;
}

.choices.is-open {
    overflow: visible;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
    user-select: none;
    color: rgb(109 116 122 / var(--tw-text-opacity));
    background: #d5dbe1 0% 0% no-repeat padding-box;
    border: 1px solid #d5dbe1;
    border-radius: 5px;
    opacity: 0.32;
}

.choices.is-disabled .choices__item {
    cursor: not-allowed;
}

.choices [hidden] {
    display: none !important;
}

.choices[data-type*='select-one'] {
    cursor: pointer;
}

.choices[data-type*='select-one'] .choices__inner {
    padding-bottom: 7.5px;
}

.choices[data-type*='select-one'] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    margin: 0;
}

.choices[data-type*='select-one'] .choices__button {
    padding: 0;
    display: none;
    background-size: 8px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: 0.25;
}

.choices[data-type*='select-one'] .choices__button:hover,
.choices[data-type*='select-one'] .choices__button:focus {
    opacity: 1;
}

.choices[data-type*='select-one'] .choices__button:focus {
    box-shadow: 0 0 0 2px #00bcd4;
}

.choices[data-type*='select-one'] .choices__item[data-value=''] .choices__button {
    display: none;
}

.choices[data-type*='select-one']::after {
    content: '';
    height: 0;
    width: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 5px;
    position: absolute;
    right: 17px;
    top: 1rem;
    margin-top: -2.5px;
    pointer-events: none;
    box-shadow: 2px 2px;
    transform: rotate(45deg) translateY(-50%);
}

.choices[data-type*='select-one'].is-open::after {
    margin-top: -7.5px;
    top: 23px;
    right: 12px;
    transform: rotate(225deg);
}

.choices[data-type*='select-one'][dir='rtl']::after {
    left: 11.5px;
    right: auto;
}

.choices[data-type*='select-one'][dir='rtl'] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0;
}

.choices[data-type*='select-multiple'] .choices__inner,
.choices[data-type*='text'] .choices__inner {
    cursor: text;
}

.choices[data-type*='select-multiple'] .choices__button,
.choices[data-type*='text'] .choices__button {
    position: relative;
    display: inline-block;
    margin-top: 0;
    margin-right: -4px;
    margin-bottom: 0;
    margin-left: 8px;
    padding-left: 16px;
    border-left: 1px solid #008fa1;
    background-size: 8px;
    width: 8px;
    line-height: 1;
    opacity: 0.75;
    border-radius: 0;
}

.choices[data-type*='select-multiple'] .choices__button:hover,
.choices[data-type*='select-multiple'] .choices__button:focus,
.choices[data-type*='text'] .choices__button:hover,
.choices[data-type*='text'] .choices__button:focus {
    opacity: 1;
}

.choices__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: #f9f9f9;
    padding: 7.5px 7.5px 3.75px;
    border: 1px solid #ddd;
    border-radius: 2.5px;
    font-size: 14px;
    overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
    border-color: #b7b7b7;
}

.is-open .choices__inner {
    border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
    border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.choices__list--single {
    display: inline-block;
    padding: 0;
    width: 100%;
}

[dir='rtl'] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px;
}

.choices__list--single .choices__item {
    width: 100%;
}

.choices__list--multiple {
    display: inline;
}

.choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 0;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 6px;
    background-color: #00bcd4;
    border: 1px solid #00a5bb;
    color: #fff;
    word-break: break-all;
    box-sizing: border-box;
    line-height: 1em;
}

.choices__list--multiple .choices__item[data-deletable] {
    padding-right: 5px;
}

[dir='rtl'] .choices__list--multiple .choices__item {
    margin-right: 0;
    margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
    background-color: #00a5bb;
    border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
    background-color: #aaaaaa;
    border: 1px solid #919191;
    padding: 5px 10px;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
    visibility: hidden;
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    top: 100%;
    margin-top: -1px;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    word-break: break-all;
    will-change: visibility;
}

[data-type='select-multiple'] {
    .choices__list--dropdown,
    .choices__list[aria-expanded] {
        width: max-content;
        min-width: 50%;
        max-width: 100%;
    }
}

.is-active.choices__list--dropdown,
.is-active.choices__list[aria-expanded] {
    visibility: visible;
}

.is-open .choices__list--dropdown,
.is-open .choices__list[aria-expanded] {
    border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown,
.is-flipped .choices__list[aria-expanded] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: 0.25rem 0.25rem 0 0;
}

.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
    position: relative;
    max-height: 220px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
    position: relative;
    padding: 10px;
    font-size: 1rem;
}

[dir='rtl'] .choices__list--dropdown .choices__item,
[dir='rtl'] .choices__list[aria-expanded] .choices__item {
    text-align: right;
}

@media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable,
    .choices__list[aria-expanded] .choices__item--selectable {
        padding-right: 5px;
    }

    .choices__list--dropdown .choices__item--selectable::after,
    .choices__list[aria-expanded] .choices__item--selectable::after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    [dir='rtl'] .choices__list--dropdown .choices__item--selectable,
    [dir='rtl'] .choices__list[aria-expanded] .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px;
    }

    [dir='rtl'] .choices__list--dropdown .choices__item--selectable::after,
    [dir='rtl'] .choices__list[aria-expanded] .choices__item--selectable::after {
        right: auto;
        left: 10px;
    }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
    background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
    opacity: 0.5;
}

.choices__item {
    cursor: default;
}

.choices__item--selectable {
    cursor: pointer;
}

.choices__item--disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    user-select: none;
    opacity: 0.5;
}

.choices__heading {
    font-weight: 600;
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #f7f7f7;
    color: gray;
}

.choices__button {
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.choices__button:focus {
    outline: none;
}

.choices__input {
    display: inline-block;
    vertical-align: baseline;
    background-color: #f9f9f9;
    font-size: 14px;
    margin-bottom: 5px;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 2px;
}

.choices__input:focus {
    outline: 0;
}

.choices__input::-webkit-search-decoration,
.choices__input::-webkit-search-cancel-button,
.choices__input::-webkit-search-results-button,
.choices__input::-webkit-search-results-decoration {
    display: none;
}

.choices__input::-ms-clear,
.choices__input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

[dir='rtl'] .choices__input {
    padding-right: 2px;
    padding-left: 0;
}

.choices__placeholder {
    opacity: 0.5;
}

/* =====  End of Choices  ====== */

.tippy-box[data-animation='fade'][data-state='hidden'] {
    opacity: 0;
}

[data-tippy-root] {
    max-width: calc(100vw - 10px);
}

.tippy-box {
    position: relative;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
}

.tippy-box[data-placement^='top'] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom;
}

.tippy-box[data-placement^='left'] > .tippy-arrow {
    right: 0;
}

.tippy-box[data-placement^='left'] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left;
}

.tippy-box[data-placement^='right'] > .tippy-arrow {
    left: 0;
}

.tippy-box[data-placement^='right'] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
}

.tippy-box[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
    width: 16px;
    height: 16px;
    color: #333;
}

.tippy-arrow:before {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
}

.tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1;
}

dialog {
    position: absolute;
    left: 0;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin: auto;
    padding: 1em;
    background: white;
    color: black;
    display: block;
    border: 0px none;
}

dialog:not([open]) {
    display: none;
}

dialog + .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

dialog.fixed {
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    list-style-type: none;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    filter: alpha(opacity=80);
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
    filter: alpha(opacity=20);
}

.loader-wrapper {
    z-index: 1000;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0);
}

.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 10000;
    display: inline-block;
    border: 6px solid #f3f3f3;
    /* Light grey */
    border-top: 6px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.formio-form {
    position: relative;
    min-height: 80px;
}

.formio-error-wrapper {
    /* command for dropdown list become red_color */
    border-color: #f5c6cb;
}

.formio-error-wrapper .formio-errors .error {
    color: #c20000;
}

.formio-error-wrapper .field-required:after {
    color: #c20000;
}

.formio-warning-wrapper {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.formio-disabled-input .form-control.flatpickr-input {
    background-color: #eee;
}

.builder-component.has-error .invalid-feedback,
.formio-component.has-error .invalid-feedback,
.formio-component.alert-danger .invalid-feedback,
.formio-component.has-message .invalid-feedback,
.formio-component-modal-wrapper.has-message .invalid-feedback,
.formio-component-modal-wrapper.has-error .invalid-feedback {
    display: block;
    color: inherit;
    margin-top: 4px;
}

.formio-errors .error {
    color: #dc3545;
}

.formio-errors .warning {
    color: #856404;
}

.formio-errors .info {
    color: #004085;
}

.formio-form-group {
    margin-bottom: 1rem;
}

.formio-wysiwyg-editor {
    min-height: 200px;
    background-color: #fff;
}

.has-feedback .form-control {
    padding-right: 10px;
}

.has-feedback .form-control[type='hidden'] {
    padding-right: 0px;
}

.has-error.bg-danger {
    padding: 4px;
}

.ql-source:after {
    content: '[source]';
    white-space: nowrap;
}

.quill-source-code {
    width: 100%;
    margin: 0px;
    background: rgb(29, 29, 29);
    box-sizing: border-box;
    color: rgb(204, 204, 204);
    font-size: 15px;
    outline: none;
    padding: 20px;
    line-height: 24px;
    font-family: Consolas, Menlo, Monaco, 'Courier New', monospace;
    position: absolute;
    top: 0;
    bottom: 0;
    border: none;
    display: none;
}

.field-required:after,
.tab-error::after {
    content: ' *';
    color: #eb0000;
}

.field-required:after {
    position: relative;
    z-index: 9;
}

.glyphicon-spin {
    -webkit-animation: formio-spin 1s infinite linear;
    -moz-animation: formio-spin 1s infinite linear;
    -o-animation: formio-spin 1s infinite linear;
    animation: formio-spin 1s infinite linear;
}

@-moz-keyframes formio-spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes formio-spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes formio-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.button-icon-right {
    margin-left: 5px;
}

.formio-component-submit .submit-success::after {
    content: '✓';
    position: relative;
    right: -4px;
    top: 1px;
    line-height: 1;
}

.formio-component-submit .submit-fail::after {
    content: '✗';
    position: relative;
    right: -4px;
    top: 1px;
    line-height: 1;
}

.card-vertical {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.card-vertical .card-body,
.tab-content,
.tab {
    flex-grow: 2;
}

.nav-tabs-vertical {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ddd;
    padding-left: 5px;
    margin-right: 10px;
    border-bottom: 0;
}

.card-vertical > .card-body,
.card-vertical > .tab-content,
.card-vertical > .tab {
    flex-basis: 85%;
}

.card-vertical ul > li > .nav-link-vertical {
    border-right-color: transparent;
    border-radius: 4px 0 0 4px;
    margin-right: 0;
}

.card-vertical ul > li > .nav-link-vertical.active {
    border-bottom-color: #ddd;
    border-right-color: transparent;
}

.card-vertical ul > li > .nav-link-vertical.active:hover {
    border-right-color: transparent;
}

.nav-tabs-vertical > li {
    margin: 0 -1px 0 0;
}

.formio-component-submit .submit-fail[disabled] {
    opacity: 1;
}

.form-control.flatpickr-input {
    background-color: #fff;
}

.input-group .flatpickr-wrapper {
    flex-grow: 1;
}

.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-calendar .flatpickr-current-month input.cur-year:focus,
.flatpickr-calendar .flatpickr-days:focus {
    outline: auto;
}

td > .form-group,
td > .formio-form-group {
    margin-bottom: 0;
}

.signature-pad-body {
    overflow: hidden;
    position: relative;
}

.signature-pad-body .form-control-feedback {
    position: absolute;
    font-size: 0.8rem;
    top: 1px;
    right: 3px;
}

.signature-pad-canvas {
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    border: 1px solid #f4f4f4;
}

.btn.signature-pad-refresh {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    padding: 3px;
    line-height: 0;
}

[dir='rtl'] .btn.signature-pad-refresh {
    left: unset;
    right: 0;
}

.formio-component-multiple .choices__input {
    width: 100%;
}

.formio-component-multiple .is-invalid {
    border-color: #f04124;
}

.formio-component-multiple :not(.is-invalid) {
    border-color: #ccc;
}

.choices__list--dropdown .choices__item--selectable {
    padding-right: 0px;
}

.signature-pad-refresh img {
    height: 1.2em;
}

.signature-pad-footer {
    text-align: center;
    color: #c3c3c3;
}

.is-active.choices__list--dropdown {
    z-index: 100;
}

.choices__item.choices__item--selectable {
    white-space: nowrap;
    overflow: hidden;
    padding-right: 25px;
    text-overflow: ellipsis;
}

/* fix for choices.js .choices__input container in rtl */
.choices[dir='rtl'] > * {
    text-align: right;
}

/* end fix for choices.js .choices__input container in rtl */
/* fix for choices.js deletable items in rtl */
.choices[dir='rtl'] .choices__list--multiple .choices__item[data-deletable] {
    padding-left: 5px;
    float: right;
}

.choices[dir='rtl'] .choices__list--multiple .choices__item[data-deletable] .choices__button {
    float: left;
    margin: 0 8px 0 -4px;
    padding-left: unset;
    padding-right: 16px;
    border-left: unset;
    border-right: 1px solid #008fa1;
    overflow: hidden;
}

/* end fix for choices.js deletable items in rtl */
@-moz-document url-prefix() {
    .choices__button {
        float: right;
    }
}

.formio-component-file .fileSelector {
    position: relative;
    padding: 15px;
    border: 2px dashed #ddd;
    text-align: center;
}

.formio-component-file .fileSelector .loader-wrapper {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.formio-component-file .fileSelector .loader-wrapper .loader {
    height: 45px;
    width: 45px;
    margin-top: -23px;
    margin-left: -23px;
}

.formio-component-file .fileSelector a {
    text-decoration: underline;
}

.formio-component-file .fileSelector.fileDragOver {
    border-color: #127abe;
}

.formio-component-file .fileSelector .glyphicon,
.formio-component-file .fileSelector .fa {
    font-size: 20px;
    margin-right: 5px;
}

[dir='rtl'] .formio-component-file .fileSelector .fa,
[dir='rtl'] .formio-component-file .fileSelector .glyphicon {
    margin-right: unset;
    margin-left: 5px;
}

.formio-component-file .fileSelector .browse {
    cursor: pointer;
}

@-webkit-keyframes formio-dialog-fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes formio-dialog-fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes formio-dialog-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes formio-dialog-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.formio-dialog {
    position: fixed;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* fix for Scrollbars not clickable on overflow #552 */
    background: rgba(0, 0, 0, 0.4);
    animation: formio-dialog-fadein 0.5s;
    /* end fix for Scrollbars not clickable on overflow #552 */
    box-sizing: border-box;
    font-size: 0.8em;
    color: #666;
}

.formio-dialog.formio-modaledit-dialog {
    font-size: inherit;
}

.formio-dialog *,
.formio-dialog *:before,
.formio-dialog *:after {
    box-sizing: inherit;
}
.formio-dialog.formio-dialog-disabled-animation,
.formio-dialog.formio-dialog-disabled-animation .formio-dialog-overlay,
.formio-dialog.formio-dialog-disabled-animation .formio-dialog-content {
    -webkit-animation: none !important;
    animation: none !important;
}

.formio-dialog-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden; /* Vendor-prefixed for WebKit browsers */
    backface-visibility: hidden; /* Standard property */
    -webkit-animation: formio-dialog-fadein 0.5s;
    animation: formio-dialog-fadein 0.5s;
    /* fix for Scrollbars not clickable on overflow #552 */
    margin-right: 15px;
    background: transparent;
    /* end fix for Scrollbars not clickable on overflow #552 */
}

.formio-dialog-no-overlay {
    pointer-events: none;
}

.formio-dialog.formio-dialog-closing .formio-dialog-overlay {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; /* Standard property */
    -webkit-animation: formio-dialog-fadeout 0.5s;
    animation: formio-dialog-fadeout 0.5s;
}

.formio-dialog-content {
    background: white;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; /* Standard property */
    -webkit-animation: formio-dialog-fadein 0.5s;
    animation: formio-dialog-fadein 0.5s;
    pointer-events: all;
    overflow: auto;
}

.formio-component-modal-wrapper-select .formio-dialog-content {
    overflow: initial;
}

.formio-dialog.formio-dialog-closing .formio-dialog-content {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; /* Standard property */
    -webkit-animation: formio-dialog-fadeout 0.5s;
    animation: formio-dialog-fadeout 0.5s;
}

.formio-dialog-close:before {
    font-family: 'Helvetica', Arial, sans-serif;
    content: '×';
    cursor: pointer;
}

html.formio-dialog-open,
body.formio-dialog-open {
    overflow: hidden;
}

.formio-dialog .tab-content {
    padding-top: 12px;
}

.formio-dialog-close {
    z-index: 1000;
}

@-webkit-keyframes formio-dialog-flyin {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes formio-dialog-flyin {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes formio-dialog-flyout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

@keyframes formio-dialog-flyout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

.formio-dialog.formio-dialog-theme-default {
    padding-bottom: 160px;
    padding-top: 160px;
}

.formio-dialog.formio-dialog-theme-default .component-edit-container {
    padding: 0.5em;
}

.formio-dialog.formio-dialog-theme-default.formio-dialog-closing .formio-dialog-content {
    -webkit-animation: formio-dialog-flyout 0.5s;
    animation: formio-dialog-flyout 0.5s;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
    -webkit-animation: formio-dialog-flyin 0.5s;
    animation: formio-dialog-flyin 0.5s;
    background: #f0f0f0;
    border-radius: 5px;
    font-family: 'Helvetica', sans-serif;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 0 auto;
    max-width: 100%;
    padding: 1em;
    position: relative;
    width: 80%;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close {
    border: none;
    background: transparent;
    cursor: pointer;
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 100;
}

.formio-clickable {
    cursor: pointer;
}

.component-settings .nav > li > a {
    padding: 8px 10px;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close:before {
    display: block;
    padding: 3px;
    background: transparent;
    color: #8a8a8a;
    content: '×';
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-close:hover:before,
.formio-dialog.formio-dialog-theme-default .formio-dialog-close:active:before {
    color: #777;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-message {
    margin-bottom: 0.5em;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-input {
    margin-bottom: 1em;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-input textarea,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='text'],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='password'],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='email'],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='url'] {
    background: #fff;
    border: 0;
    border-radius: 3px;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0 0 0.25em;
    min-height: 2.5em;
    padding: 0.25em 0.67em;
    width: 100%;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-input textarea:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='text']:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='password']:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='email']:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type='url']:focus {
    box-shadow: inset 0 0 0 2px #8dbdf1;
    outline: none;
}

.formio-dialog-buttons {
    display: flex;
    justify-content: flex-end;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-buttons:after {
    content: '';
    display: table;
    clear: both;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-button {
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    float: right;
    font-family: inherit;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    line-height: 1em;
    margin: 0 0 0 0.5em;
    padding: 0.75em 2em;
    text-transform: uppercase;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-button:focus {
    -webkit-animation: formio-dialog-pulse 1.1s infinite;
    animation: formio-dialog-pulse 1.1s infinite;
    outline: none;
}

@media (max-width: 568px) {
    .formio-dialog.formio-dialog-theme-default .formio-dialog-button:focus {
        -webkit-animation: none;
        animation: none;
    }
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-button.formio-dialog-button-primary {
    background: #3288e6;
    color: #fff;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-button.formio-dialog-button-secondary {
    background: #e0e0e0;
    color: #777;
}

.formio-dialog-content .panel {
    margin: 0;
}

.formio-dialog-content [ref='dialogHeader'] {
    padding-right: 15px;
}

.formio-placeholder {
    position: absolute;
    color: #999;
}

.formio-dialog .formio-dialog-close {
    cursor: pointer;
}

.formio-iframe {
    border: none;
    width: 100%;
    height: 1000px;
}

.inline-form-button {
    margin-right: 10px;
}

.tooltip {
    opacity: 1;
}

.tooltip[x-placement='right'] .tooltip-arrow {
    border-right: 5px solid black;
}

.tooltip[x-placement='right'] .tooltip-inner {
    margin-left: 8px;
}

.control-label--bottom {
    margin-bottom: 0;
    margin-top: 5px;
}

.formio-component-label-hidden {
    position: relative;
}

.formio-hidden {
    margin: 0;
}

.formio-removed {
    display: none;
}

.control-label--hidden {
    position: absolute;
    top: 6px;
    right: 5px;
}

.formio-component-datetime .control-label--hidden.field-required {
    right: 45px;
    z-index: 3;
}

.formio-component-survey .control-label--hidden.field-required,
.formio-component-selectboxes .control-label--hidden.field-required {
    top: 0;
}

.formio-component-resource .control-label--hidden.field-required,
.formio-component-select .control-label--hidden.field-required {
    right: 40px;
    z-index: 2;
}

.formio-component-radio .control-label--hidden.field-required:after,
.formio-component-selectboxes .control-label--hidden.field-required:after {
    display: none;
}

.formio-component-radio.formio-component-label-hidden.required .form-check-label:before,
.formio-component-selectboxes.formio-component-label-hidden.required .form-check-label:before {
    position: relative;
    content: '* ';
    color: #eb0000;
}

.formio-component-radio.formio-component-label-hidden.required
    .label-position-right.form-check-label:before,
.formio-component-selectboxes.formio-component-label-hidden.required
    .label-position-right.form-check-label:before {
    right: 20px;
}

/* Fix for Hidden checkbox in component editform. */
.formio-component-hidden:not(.formio-component-checkbox),
.formio-component-datasource {
    margin-bottom: 0;
}

.checkbox-inline label,
.radio-inline label {
    font-weight: 400;
    cursor: pointer;
}

.editgrid-listgroup {
    margin-bottom: 10px;
    overflow-wrap: break-word;
}

.tree-listgroup {
    flex-direction: row;
}

.formio-component-submit button[disabled] + .has-error {
    display: block;
}

.formio-choices.form-group,
.formio-choices.formio-form-group {
    margin-bottom: 0;
}

.formio-choices[data-type='select-multiple'] .form-control {
    height: auto;
}

.form-control.formio-multiple-mask-select {
    width: 15%;
    z-index: 4;
}

.form-control.formio-multiple-mask-input {
    width: 85%;
}

.input-group.formio-multiple-mask-container {
    width: 100%;
}

.formio-component .table {
    margin-bottom: 0;
    word-break: break-all;
}

.formio-component-htmlelement {
    word-wrap: break-word;
}

.formio-component-htmlelement ol,
.formio-component-htmlelement ul {
    margin-left: 10px;
}

.editgrid-table-container {
    margin-bottom: 10px;
    max-width: calc(100vw - 140px);
}

.editgrid-table-container .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.editgrid-table-column {
    border: none;
}

.editgrid-table-head {
    border: 1px solid #ddd;
}

.editgrid-table-body {
    border: 1px solid #ddd;
    border-top: 0;
}

.formio-hide-label-panel-tooltip {
    margin-top: -10px;
    margin-left: -10px;
}
.is-disabled .choices__list--multiple .choices__item .choices__button {
    display: none;
}

.formio-collapse-icon {
    cursor: pointer;
    margin-right: 4px;
}

[dir='rtl'] .formio-collapse-icon {
    margin-right: unset;
    margin-left: 4px;
}

.formio-component-datetime .form-control[type='datetime-local'] ~ .input-group-addon,
.formio-component-dateTime .form-control[type='datetime-local'] ~ .input-group-addon {
    width: auto;
}

.formio-component-datagrid .formio-datagrid-remove {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity 200ms linear,
        visibility 0ms 200ms;
}

.formio-component-datagrid {
    overflow-x: auto;
}

.formio-component-datagrid .datagrid-table,
.formio-component-datagrid .datagrid-table td,
.formio-component-datagrid .datagrid-table th {
    border: 3px solid #ddd !important;
    padding: 10px;
}

.formio-component-datagrid .datagrid-table > tbody > tr > td:last-child {
    position: relative;
}

.formio-component-datagrid
    .datagrid-table
    > tbody
    > tr:hover
    > td:last-child
    .formio-datagrid-remove {
    visibility: visible;
    opacity: 1;
    transition:
        visibility 0ms,
        opacity 200ms linear;
}

.datagrid-table > tbody > tr > td {
    word-break: break-all;
}

.formio-component-modaledit .formio-modaledit-view-container {
    position: relative;
    border: 1px solid #ddd;
    min-height: 34px;
    padding: 6px 12px;
    cursor: text;
}

td .formio-component-modaledit .formio-modaledit-view-container {
    padding: 0;
    border-style: none;
}

.formio-component-modaledit .formio-modaledit-edit {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity 200ms linear,
        visibility 0ms 200ms;
}

.formio-component-modaledit .formio-modaledit-view-container:hover .formio-modaledit-edit {
    visibility: visible;
    opacity: 1;
    transition:
        visibility 0ms,
        opacity 200ms linear;
}

.formio-modaledit-dialog .formio-modaledit-close {
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 0;
}

.reset-margins html,
.reset-margins body,
.reset-margins div,
.reset-margins span,
.reset-margins applet,
.reset-margins object,
.reset-margins iframe,
.reset-margins h1,
.reset-margins h2,
.reset-margins h3,
.reset-margins h4,
.reset-margins h5,
.reset-margins h6,
.reset-margins p,
.reset-margins blockquote,
.reset-margins pre,
.reset-margins a,
.reset-margins abbr,
.reset-margins acronym,
.reset-margins address,
.reset-margins big,
.reset-margins cite,
.reset-margins code,
.reset-margins del,
.reset-margins dfn,
.reset-margins em,
.reset-margins img,
.reset-margins ins,
.reset-margins kbd,
.reset-margins q,
.reset-margins s,
.reset-margins samp,
.reset-margins small,
.reset-margins strike,
.reset-margins strong,
.reset-margins sub,
.reset-margins sup,
.reset-margins tt,
.reset-margins var,
.reset-margins b,
.reset-margins u,
.reset-margins i,
.reset-margins center,
.reset-margins dl,
.reset-margins dt,
.reset-margins dd,
.reset-margins ol,
.reset-margins ul,
.reset-margins li,
.reset-margins fieldset,
.reset-margins form,
.reset-margins label,
.reset-margins legend,
.reset-margins table,
.reset-margins caption,
.reset-margins tbody,
.reset-margins tfoot,
.reset-margins thead,
.reset-margins tr,
.reset-margins th,
.reset-margins td,
.reset-margins article,
.reset-margins aside,
.reset-margins canvas,
.reset-margins details,
.reset-margins embed,
.reset-margins figure,
.reset-margins figcaption,
.reset-margins footer,
.reset-margins header,
.reset-margins hgroup,
.reset-margins menu,
.reset-margins nav,
.reset-margins output,
.reset-margins ruby,
.reset-margins section,
.reset-margins summary,
.reset-margins time,
.reset-margins mark,
.reset-margins audio,
.reset-margins video {
    margin: 0;
}

.ck-body .ck.ck-balloon-panel {
    z-index: 101000;
}

.formio-component-select select[disabled='disabled'] {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.formio-component-select div[disabled='disabled'] button,
.formio-component-select .choices.is-disabled[data-type*='select-one']:after {
    display: none;
}

.datagrid-group-label.collapsed > td {
    display: none;
}

.datagrid-group-header.clickable {
    cursor: pointer;
}

.datagrid-group-header.clickable .datagrid-group-label:before {
    display: inline-block;
    vertical-align: middle;
    content: '▾';
    margin: 0 5px;
}

.datagrid-group-header.clickable.collapsed .datagrid-group-label:before {
    content: '▸';
}

.formio-component.alert-danger .help-block,
.formio-component.alert-warning .help-block {
    color: inherit;
}

.tree__level_even {
    background-color: #f6f6f6;
}

.tree__node-content {
    margin-bottom: 10px;
    overflow-wrap: break-word;
}

.tree__node-children {
    margin: 0;
}

.formio-select-autocomplete-input {
    /* we can't use display: none or visibility: hidden because autocomplete won't work on hidden field */
    opacity: 0;
    position: relative;
    z-index: -1;
    display: block;
    height: 0;
    border: none;
}

.has-error > .help-block {
    margin-top: 5px;
    margin-bottom: 10px;
}

.no-top-border-table > .table > tbody > tr:first-child > td {
    border-top: none;
}

.table > tbody > tr > td.cell-align-left {
    text-align: left;
}

.table > tbody > tr > td.cell-align-center {
    text-align: center;
}

.table > tbody > tr > td.cell-align-center > div {
    margin-left: auto;
    margin-right: auto;
}

.table > tbody > tr > td.cell-align-right {
    text-align: right;
}

.table > tbody > tr > td.cell-align-right > div {
    margin-left: auto;
}

.table-responsive[ref='component'] {
    overflow-x: visible;
}

.formio-component-textarea .alert .ck-editor__editable {
    color: inherit;
}

.formio-component-textarea .ck.ck-editor__editable .image .ck-progress-bar {
    height: 4px;
}

.formio-component-textarea .ck.ck-editor ul,
.formio-component-textarea .ck.ck-editor ol {
    margin-left: 10px;
}

div[data-oembed-url] {
    width: 100%;
}

.radio label.label-position-left,
.checkbox label.label-position-left,
.radio label.label-position-top,
.checkbox label.label-position-top,
.radio label.label-position-bottom,
.checkbox label.label-position-bottom {
    padding-left: 0;
}

.radio label.label-position-top span,
.checkbox label.label-position-top span,
.radio label.label-position-bottom span,
.checkbox label.label-position-bottom span {
    display: block;
}

.radio label.label-position-top input[type='radio'],
.checkbox label.label-position-top input[type='checkbox'],
.radio label.label-position-bottom input[type='radio'],
.checkbox label.label-position-bottom input[type='checkbox'] {
    position: relative;
    margin-left: 0;
}

.radio label.label-position-top input[type='radio'],
.checkbox label.label-position-top input[type='checkbox'] {
    margin-top: 4px;
}

.radio label.label-position-bottom input[type='radio'],
.checkbox label.label-position-bottom input[type='checkbox'] {
    margin-bottom: 8px;
}
.checkbox label.label-position-left input[type='checkbox'] {
    margin-left: 4px;
    position: relative;
}

.open-modal-button {
    width: 100%;
    text-align: left;
    white-space: normal;
    height: auto;
}

.formio-component-modal-wrapper-signature .open-modal-button {
    text-align: center;
    height: 100%;
    font-size: 1.4em;
    padding: 0;
    margin: 0;
}

/* ckeditor5-image/theme/image.css */
.formio-component-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;
}

/* ckeditor5-image/theme/image.css */
.formio-component-content .image > img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
}

/* ckeditor5-image/theme/imagecaption.css */
.formio-component-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: hsl(0deg, 0%, 20%);
    background-color: hsl(0deg, 0%, 97%);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
}

/* ckeditor5-image/theme/imageresize.css */
.formio-component-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.formio-component-content .image.image_resized img {
    width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.formio-component-content .image.image_resized > figcaption {
    display: block;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.formio-component-content .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
}

/* ckeditor5-image/theme/imagestyle.css */
.formio-component-content .image-style-side:not(.image_resized),
.formio-component-content .image-style-align-left:not(.image_resized),
.formio-component-content .image-style-align-center:not(.image_resized),
.formio-component-content .image-style-align-right:not(.image_resized) {
    max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.formio-component-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.formio-component-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.formio-component-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.formio-component-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.formio-component-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0deg, 0%, 80%);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.formio-component-content[dir='rtl'] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0deg, 0%, 80%);
}

.formio-component-content .text-tiny {
    font-size: 0.7em;
}

.formio-component-content .text-small {
    font-size: 0.85em;
}

.formio-component-content .text-big {
    font-size: 1.4em;
}

.formio-component-content .text-huge {
    font-size: 1.8em;
}

.formio-component-content ol {
    padding-inline-start: 40px;
}

.formio-component-address.formio-component-label-hidden > label.field-required {
    z-index: 1;
}

.formio-component-address.formio-component-label-hidden
    > label.field-required
    ~ .address-autocomplete-container
    .address-autocomplete-remove-value-icon {
    right: 20px;
}

.address-autocomplete-container {
    position: relative;
}

.address-autocomplete-container .address-autocomplete-remove-value-icon {
    cursor: pointer;
    position: absolute;
    margin-top: -9px;
    right: 10px;
    top: 50%;
}

.address-autocomplete-container .address-autocomplete-remove-value-icon--hidden {
    display: none;
}

.autocomplete {
    background: white;
    font:
        14px/22px '-apple-system',
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        sans-serif;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid rgba(50, 50, 50, 0.6);
    z-index: 11000;
}

.autocomplete > div {
    cursor: pointer;
    padding: 6px 10px;
}

.autocomplete > div:hover:not(.group),
.autocomplete > div.selected {
    background: #1e90ff;
    color: #ffffff;
}

.field-wrapper {
    display: flex;
}

.field-wrapper--reverse {
    flex-direction: row-reverse;
}

.field-wrapper .field-label--right {
    text-align: right;
}

.formio-component-modal-wrapper {
    margin-bottom: 10px;
}

.formio-component-modal-wrapper .open-modal-button {
    height: auto;
}

.formio-component-modal-wrapper .component-rendering-hidden {
    visibility: hidden;
}

.formio-component-textarea div.formio-editor-read-only-content[ref='input'] {
    white-space: pre-wrap;
}

.formio-editor-read-only-content img {
    max-width: 100%;
}

.formio-editor-read-only-content li[data-list='bullet'] {
    list-style-type: none;
}

.formio-editor-read-only-content li[data-list='bullet'] .ql-ui {
    padding-right: 0.5rem;
}

.formio-editor-read-only-content li[data-list='bullet'] .ql-ui::before {
    content: '•';
}

.formio-editor-read-only-content li[data-list='ordered'] {
    list-style-type: none;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
}

.formio-editor-read-only-content li[data-list='ordered'] .ql-ui {
    padding-right: 0.5rem;
}

.formio-editor-read-only-content li[data-list='ordered'] .ql-ui::before {
    content: counter(list-0, decimal) '. ';
}

.formio-editor-read-only-content figure.table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double #b3b3b3;
    table-layout: fixed;
}

.formio-editor-read-only-content figure.table table th,
.formio-editor-read-only-content figure.table table td {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
}

.formio-component-textfield .pull-right:not(:last-child),
.formio-component-textarea .pull-right:not(:last-child),
.formio-component-password .pull-right:not(:last-child) {
    padding-left: 12px;
}

.formio-form > div > nav > ul.pagination {
    flex-flow: wrap row;
    justify-content: flex-start;
}

.formio-form > div > nav > ul.pagination .page-link {
    cursor: pointer;
    color: #1c74d9;
}

.formio-form > div > nav > ul.pagination .page-item.active .page-link {
    color: #fff;
    background-color: #1c74d9;
    border-color: #1c74d9;
}

.classic-pagination {
    border-bottom: solid 1px #e0e0e0;
    padding: 0 15px 10px 15px;
    line-height: 1em;
}

.classic-pagination-page {
    padding: 0;
    position: relative;
}

.classic-pagination-title {
    color: #595959;
    font-size: 16px;
    margin-bottom: 5px;
}

.classic-pagination-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #fbe8aa;
    top: 40px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.classic-pagination-dot::after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #fbbd19;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.classic-pagination .progress,
.classic-pagination-progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    box-shadow: none;
    margin: 20px 0;
    border: none;
    padding: 0;
    background-color: #f6f6f6;
}

.classic-pagination .progress-bar,
.classic-pagination-progress-bar {
    width: 0px;
    height: 10px;
    box-shadow: none;
    background: #fbe8aa;
}

.classic-pagination-page.complete .progress-bar,
.classic-pagination-page.complete .classic-pagination-progress-bar {
    width: 100%;
}

.classic-pagination-page.active .progress-bar,
.classic-pagination-page.active .classic-pagination-progress-bar {
    width: 50%;
}

.classic-pagination-page.disabled .classic-pagination-dot {
    background-color: #f5f5f5;
}

.classic-pagination-page.disabled .classic-pagination-dot::after {
    opacity: 0;
}

.classic-pagination-page:first-child .progress,
.classic-pagination-page:first-child .classic-pagination-progress {
    left: 50%;
    width: 50%;
}

.classic-pagination-page:first-child.active .progress-bar,
.classic-pagination-page:first-child.active .classic-pagination-progress-bar {
    width: 0%;
}

.classic-pagination-page:last-child .progress,
.classic-pagination-page:last-child .classic-pagination-progress {
    width: 50%;
}

.classic-pagination-page:last-child.active .progress-bar,
.classic-pagination-page:last-child.active .classic-pagination-progress-bar {
    width: 100%;
}

.pac-container {
    z-index: 11000;
}

[ref='buttonMessageContainer'].has-error {
    cursor: pointer;
}

[ref='passwordStrengthIndicator'] {
    display: inline;
}

.formio-security-indicator {
    display: flex;
    height: 5px;
}

.formio-security-indicator [class^='security-'] {
    width: 100%;
    height: 100%;
}

.formio-security-indicator .security-low {
    background-color: #c51e00;
}

.formio-security-indicator .security-medium {
    background-color: #ebb400;
}

.formio-security-indicator .security-high {
    background-color: #bddf00;
}

.formio-security-indicator .security-very-high {
    background-color: #009118;
}

.formio-component-textarea .formio-editor-read-only-content .text-big {
    font-size: 1.4em;
}

.formio-component-textarea .formio-editor-read-only-content .text-huge {
    font-size: 1.8em;
}

.formio-component-textarea .formio-editor-read-only-content .text-small {
    font-size: 0.85em;
}

.formio-component-textarea .formio-editor-read-only-content .text-tiny {
    font-size: 0.7em;
}

.formio-component [ref='valueMaskInput'] {
    display: none;
}

.formio-wizard-nav-container {
    display: flex;
}

.formio-wizard-nav-container li {
    margin-right: 0.5rem;
}

@media not all and (min-width: 30em) {
    .formio-wizard-nav-container {
        flex-direction: column;
    }

    .formio-wizard-nav-container li {
        margin-right: 0;
    }

    .formio-wizard-nav-container li .btn {
        width: 100%;
        margin-bottom: 0.25rem;
    }
}

.formio-tooltip__trigger {
    cursor: pointer;
}

.formio-tooltip__body {
    background-color: #1b1b1b;
    border-radius: 0.25rem;
    bottom: 0;
    color: #f0f0f0;
    display: none;
    font-size: 1rem;
    padding: 0.5rem;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    width: auto;
    white-space: pre;
    z-index: 1000;
}

.formio-tooltip__body.formio-tooltip--is-set {
    display: block;
}

.formio-tooltip__body--whitespace {
    white-space: normal;
    width: 250px;
}

.formio-tooltip__body--right {
    top: auto;
    transform: translateX(0);
}

.formio-tooltip__body--left {
    top: auto;
    left: 0;
    right: auto;
    transform: translateX(0);
}

.formio-tooltip__body--bottom {
    bottom: auto;
    top: 0;
}

.formio-tooltip__wrapper {
    position: relative;
}

.formio-tooltip__wrapper > span {
    font-weight: normal;
}

.ace_editor,
.ace_editor div,
.ace_editor span {
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Droid Sans Mono', 'Consolas', monospace !important;
}

span[role='link'] {
    text-decoration: underline;
    cursor: pointer;
}

.formbuilder {
    position: relative;
}

.drag-container {
    padding: 10px;
    border: dotted 2px #e8e8e8;
}

.drag-container:hover {
    cursor: move;
    border: dotted 2px #ccc;
}

.drag-container.formio-builder-form,
.drag-container.formio-builder-form:hover,
.panel-body > .drag-container.formio-builder-components,
.panel-body > .drag-container.formio-builder-components:hover,
.tab-pane > .drag-container.formio-builder-components,
.tab-pane > .drag-container.formio-builder-components:hover {
    padding: 0 0 1rem 0;
    border: none;
}

.component-btn-group {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    right: 0;
    z-index: 1000;
    margin-top: -2px;
}

.builder-component {
    position: relative;
    min-height: 15px;
    margin-bottom: 15px;
}

.builder-component .formio-component-htmlelement {
    border: dotted 2px #e8e8e8;
}

.builder-component .formio-component-htmlelement [ref='html']:empty:before {
    content: 'HTML Content';
    color: #aaa;
}

.builder-component:not(:hover) .component-btn-group {
    display: none;
}

.builder-group-button {
    background-color: transparent;
    white-space: normal;
    text-align: left;
}

.form-builder-group-header {
    padding: 0;
}

.component-btn-group .component-settings-button {
    float: right;
    z-index: 1001;
    margin: 4px 4px 0 0;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(48, 113, 169, 0.6);
    -moz-box-shadow: 0px 0px 10px 1px rgba(48, 113, 169, 0.6);
    box-shadow: 0px 0px 10px 1px rgba(48, 113, 169, 0.6);
}

.formbuilder .formio-component-hidden,
.formbuilder .formio-component-content,
.formbuilder .formio-component-form,
.formbuilder .formio-component-datasource {
    border: 2px dashed #ddd;
}

.formbuilder .formio-component-form,
.formbuilder .formio-component-hidden,
.formbuilder .formio-component-datasource {
    min-height: 3em;
    text-align: center;
    color: #aaa;
    padding-top: 0.5em;
}

.btn-xxs,
.btn-group-xxs > .btn,
.component-btn-group .component-settings-button {
    padding: 2px 2px;
    font-size: 10px;
    line-height: 1.2em;
    border-radius: 0;
    width: 18px;
    height: 18px;
}

.formcomponents .formcomponent {
    text-align: left;
    padding: 5px 5px 5px 8px;
    margin-top: 0.2rem;
    font-size: 0.8em;
    line-height: 1.2;
    border-radius: 0.3em;
}

.form-builder-panel .panel-body {
    padding: 5px;
}

.formio-component-tabs .ui.tabular.menu .item {
    padding: 0.8em;
}

.formio-pdf-builder {
    position: relative;
}

.formio-drop-zone {
    display: none;
    position: absolute;
    z-index: 10;
    background-color: #0d87e9;
    opacity: 0.1;
}

.formio-drop-zone.enabled {
    display: inherit;
}

.component-settings .formio-dialog-content {
    max-height: 100%;
}

.component-settings .formio-dialog-content .ck-editor__editable ol {
    padding-inline-start: 40px;
}

.component-btn-group .btn.component-settings-button-paste {
    display: none;
}

.builder-paste-mode .component-settings-button-paste {
    display: inherit !important;
}

.wizard-page-label {
    cursor: pointer;
    border-radius: 0;
}

.panel-body .drag-and-drop-alert {
    margin-bottom: 0;
}

.builder-sidebar_scroll {
    position: sticky;
    top: 15px;
    max-height: 100vh;
    overflow-y: auto;
}

.builder-sidebar_search {
    margin-bottom: 10px;
    appearance: auto;
}

.formio-wizard-builder-component-title {
    color: #6c757d;
    text-align: center;
    padding: 0.5rem;
}

.formio-wizard-position {
    position: relative;
}

.formio-settings-help {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    margin-top: 10px;
}

.help-block {
    margin: 0px;
}

.builder-sidebar .btn {
    white-space: normal;
}

/* Styles for component edit modal */
.component-settings {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.component-edit-container {
    height: auto;
    overflow: hidden;
}

.component-edit-content {
    height: calc(100% - 4em);
}

.component-edit-tabs.col-sm-6 {
    min-height: 87vh;
    height: 100%;
}

.component-edit-tabs.col-sm-12 {
    height: calc(100% - 4em);
    overflow-y: auto;
}

.component-edit-tabs.col-sm-12 .editForm {
    height: calc(100% - 4em);
    overflow-y: auto;
}

.progress.pdf-progress {
    height: 2rem;
}

.progress.pdf-progress .progress-bar {
    font-size: 1rem;
    line-height: 2rem;
}

.builder-sidebar.disabled .formcomponent {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
}

.builder-component-selected {
    border: 2px dashed #919191;
    outline: none !important;
}
