/* if going to use formio builder then below line will need */
.text-sm {
    font-size: 1rem;
}

.formio-component .form-control {
    @apply w-full text-sm font-medium input input-bordered h-10 rounded-md border-[0.063rem] focus-within:outline-none border-neutral-100 focus:border-primary focus-within:border-primary disabled:bg-neutral-200 disabled:border-neutral-200 disabled:text-neutral-200;
}

.my-textarea {
    max-height: 50vh;
}

.rta {
    position: relative;
    font-size: 18px;
    width: 100%;
}

.rta__loader.rta__loader--empty-suggestion-data {
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(27, 31, 35, 0.1);
    padding: 5px;
}

.rta--loading .rta__loader.rta__loader--suggestion-data {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
}

.rta--loading .rta__loader.rta__loader--suggestion-data > * {
    position: relative;
    top: 50%;
}

.rta__textarea {
    width: 100%;
    height: 50vh;
    font-size: 1em;
}

.formulaEditor.rta__textarea {
    @apply w-full text-sm font-medium input input-bordered h-10 rounded-md border-[0.063rem] focus-within:outline-none border-neutral-100 focus:border-primary focus-within:border-primary disabled:bg-neutral-200 disabled:border-neutral-200 disabled:text-neutral-200;
}

.rta__autocomplete {
    position: absolute;
    display: block;
    border: 1px solid #dfe2e5;
    margin-top: 1em;
}

.rta__autocomplete--top {
    margin-top: 0;
    margin-bottom: 1em;
}

.rta__list {
    margin: 0;
    padding: 0;
    background: #fff;
    border: 1px solid #dfe2e5;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(27, 31, 35, 0.1);
    list-style: none;
}

.rta__entity {
    background: white;
    width: 100%;
    text-align: left;
    outline: none;
}

.rta__entity:hover {
    cursor: pointer;
}

.rta__item:not(:last-child) {
    border-bottom: 1px solid #dfe2e5;
}

.rta__item {
    padding: 5px;
}

.rta__item--selected {
    background: #0366d6;
}

.rta__entity > * {
    padding-left: 4px;
    padding-right: 4px;
}

.rta__entity--selected {
    color: #fff;
    text-decoration: none;
    background: #0366d6;
}

/* customized css to override the formio styles */

.formio-component-form {
    /* hided dynamic form bg color */
    @apply px-2 py-0 !important;
}

.col-form-label {
    @apply text-sm font-medium leading-5 text-neutral-700 block;
}

.formio-component .formio-errors .invalid-feedback {
    @apply text-end text-secondary font-medium flex gap-1 justify-end items-center pt-1;
}

.builder-component.has-error .invalid-feedback,
.formio-component.has-error .invalid-feedback,
.formio-component.alert-danger .invalid-feedback,
.formio-component.has-message .invalid-feedback,
.formio-component-modal-wrapper.has-message .invalid-feedback,
.formio-component-modal-wrapper.has-error .invalid-feedback,
.custom-error-label {
    display: unset;
    color: unset;
    margin-top: unset !important;
    @apply text-end text-secondary font-medium flex gap-1 justify-end items-center !important;
}

.form-text {
    @apply text-sm pt-[0.1rem];
}

.formio-component .form-check-label .label-position-right {
    @apply label cursor-pointer flex items-center;

    span {
        @apply label-text font-medium;
    }
}

.formio-component .form-check-label .label-position-right input {
    @apply radio h-12 border-neutral-200 checked:bg-primary;
}

.formio-component .formio-component-tags .choices__list--multiple .choices__item {
    @apply bg-primary-50 text-black rounded-md border-primary-100 bg-transparent p-2 !important;
}

/* dropdown option  */

.formio-component .choices[data-type*='text'] .choices__button {
    @apply border-0 rounded-sm bg-transparent bg-[url('assets/images/close-icon.svg')];
}

.formio-component .has-feedback .form-control {
    @apply justify-center;
}

.formio-component .form-control.ui.fluid.selection.dropdown {
    @apply flex flex-wrap;
    flex-flow: column;
    min-height: 2.5rem;
    max-height: 10rem;
    height: auto;
    overflow: auto;
}

/* tag and choices  */
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted :hover {
    @apply bg-primary-50 !important;
}

.formio-component .choices__button {
    @apply bg-white;
}

.formio-component .choices__input {
    @apply bg-transparent;
}

.choices__input {
    @apply text-sm;
}

.formio-component .choices__inner {
    @apply bg-white mt-1;
}

/* dynamic form checkbox */
.formio-component .form-check {
    @apply !border-none !w-auto;
}

.form-group:not(.defaultCheckbox) .form-check.checkbox {
    .form-check-input {
        @apply toggle border-2 border-neutral-200 bg-neutral-200 cursor-pointer;
    }

    .form-check-input:checked {
        @apply bg-primary border-primary;
    }

    .form-check-input:hover {
        @apply hover:bg-neutral-400 hover:border-neutral-400;
    }

    .form-check-input:checked:hover {
        @apply checked:bg-primary-200 checked:border-primary-200;
    }

    .form-check-input:disabled {
        @apply bg-neutral-200 text-neutral-400 cursor-not-allowed;
    }

    .form-check-input:disabled:checked {
        @apply bg-primary;
    }

    span {
        line-height: 1.5;
    }
}

.defaultCheckbox .form-check-label {
    @apply flex-row gap-x-2;
}

.columnAlignCenter {
    @apply inline-flex items-center;
}

/* gap between label and radio or check box */
.form-check-label {
    @apply flex gap-x-2 justify-end flex-row-reverse items-center;

    span {
        @apply font-medium text-neutral-700 leading-6;
    }
}

/* radio button */
.formio-component-radio .radio {
    @apply border-0 mt-2 w-fit flex pe-4;

    .form-check-input {
        outline: none !important;
        @apply radio h-[1.2rem] w-[1.2rem] border border-neutral-200 checked:bg-primary;
    }

    .radio .pl-0 .form-check-inline {
        @apply w-fit;
    }

    .form-check-label {
        span {
            @apply font-medium text-neutral-700 leading-6 whitespace-nowrap;
        }
    }
}

/* multiselect dropdown */
.formio-component .formio-choices[data-type='select-multiple'] .form-control {
    @apply h-9 justify-normal pl-1 py-0 cursor-pointer;

    .choices__item {
        @apply bg-primary-50 text-sm font-medium text-black rounded-md border-primary-100 mt-1 !bg-none;
    }

    .choices__button {
        @apply border-0 bg-transparent bg-[url('assets/images/close-icon.svg')];
    }

    .choices__list .choices__list--multiple {
        .choices__item.is-highlighted {
            @apply border-primary-100;
        }
    }

    .choices__input {
        @apply mt-2;
    }
}

.choices__item.choices__item--selectable.is-selected {
    font-weight: 500;
    background: #f1f3f5;
}

.formio-component {
    @apply py-0 leading-none;
    textarea {
        @apply p-2 max-h-[50vh];
    }
}

.formio-component .formio-component-textfield,
.formio-component .formio-component-datetime,
.formio-component .formio-component-tags {
    @apply p-0;
}

.formio-component .col-form-label {
    @apply pb-[0.2rem] inline-block;
}

/* To hide the formio button */
.formio-component-button {
    @apply hidden;
}

.translation-form {
    .formio-component-resetToDefault {
        @apply contents;
        button {
            @apply gap-0;
        }
    }
    .fieldset-body > div:first-child {
        display: inline-block;
        width: calc(100% - 12rem);
    }
    @media (min-width: 1920px) {
        .fieldset-body > div:first-child {
            width: calc(100% - 10rem);
        }
    }
    @media (min-width: 4500px) {
        .fieldset-body > div:first-child {
            width: calc(100% - 10rem);
        }
    }
}

/* hide - filed errors listing top of the page after trigger submission  */
div[id^='error-list'] {
    display: none;
}

/* applied text-area height as global */
textArea,
.formio-component-textarea .formio-editor-read-only-content {
    min-height: 80px;
    @apply p-2;
}

.form-group {
    margin-bottom: 15px;
}

.formio-component-multiple {
    min-height: 52px;
    /* margin-bottom: 22px; */
}

.formio-component-textarea {
    textarea {
        max-height: 200px;
    }
}

.formio-component-tags {
    min-height: 79px;
}

.formio-component-checkbox {
    min-height: 55px;
    display: flex;
    align-items: center;
}

/* visibility hidden -> display none */
.visibilityToDisplayNone .col-md-3:has(.formio-hidden),
.visibilityToDisplayNone .col-md-4:has(.formio-hidden),
.visibilityToDisplayNone .col-md-6:has(.formio-hidden),
.visibilityToDisplayNone .col-md-8:has(.formio-hidden),
.visibilityToDisplayNone .col-md-10:has(.formio-hidden),
.visibilityToDisplayNone .col-md-12:has(.formio-hidden),
.col-md-4:has(> .formio-hidden), /* vendor billing info JSON */
/**/
/* sr-only - hided the extra label for numeric fields  */
.sr-only,
.formio-hidden {
    @apply !hidden;
}

/* read-only fields invisible due to default styles. So we overwrite the style here. */
.formio-editor-read-only-content {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    color: rgb(109 116 122 / var(--tw-text-opacity));
    opacity: 1;
    background: #f2f4f6;
    border: 1px solid #d5dbe1;
    border-radius: 5px;
    cursor: not-allowed;
    min-height: 35px;
    @apply mt-1;
}

/* disabled fields style overwrite */
.formio-form .form-group.formio-component .form-control:disabled,
.formio-form .form-group.formio-component .form-control[disabled] {
    /* command for edit and view UI text should be lite dark */
    background: #f1f3f5 0% 0% no-repeat padding-box;
    border: 1px solid #d5dbe1;
    border-radius: 5px;
    opacity: 0.9;
    @apply text-neutral-1000;
}
/* rich text editor style on disable */
.ql-container.ql-disabled {
    background: #f1f3f5 0% 0% no-repeat padding-box;
    border: 1px solid #d5dbe1 !important;
    border-radius: 5px;
    opacity: 0.9;
    --tw-text-opacity: 1;
    font-weight: 500;
}
.ql-container.ql-disabled > p > span {
    background-color: unset !important;
    color: rgb(78 82 86 / var(--tw-text-opacity)) !important;
}

.choices .form-group .formio-choices .is-disabled {
    color: rgb(109 116 122 / var(--tw-text-opacity));
    background: #d5dbe1 0% 0% no-repeat padding-box !important;
    border: 1px solid #d5dbe1;
    border-radius: 5px;
    opacity: 0.32;
}

.formio-component-radio {
    min-height: 60px;
}

.formio-component-htmlelement {
    min-height: initial;
}

.formio-component-select {
    @apply p-0;
}

.row {
    @apply px-0;
}

.countValidation {
    position: relative;
}

.countValidation:after {
    content: '';
    background-image: url('assets/images/validCount.svg');
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    background-size: contain;
    right: 0.9rem;
    width: 1rem;
    height: 1rem;
    top: 2.3rem;
    z-index: 1;
}

.countValidation.countMissMatch:after {
    background-image: url('assets/images/invalidCount.svg');
}

.formio-component-datetime {
    position: relative;
}

.formio-component-datetime .input-group-append {
    display: none;
}

.formio-component-datetime > div {
    position: relative;
    z-index: 1;
}

.formio-component-datetime .form-control {
    padding-left: 30px;
    background: transparent;
}

.formio-component-datetime div[ref='element']::after {
    content: '';
    position: absolute;
    top: 0.7rem;
    left: 8px;
    z-index: 1;
    width: 20px;
    height: 20px;
    background-image: url('assets/images/date-picker.svg');
    background-position: top;
    background-size: 14px;
    background-repeat: no-repeat;
    object-fit: fill;
    opacity: 0.7;
}

@media (min-width: 1400px) {
    .formio-component-datetime div[ref='element']::after {
        top: 0.8rem;
    }
}

.formio-component-datetime.formio-hidden::after {
    content: none;
}

.form-check-input:disabled {
    --togglehandleborder: 0 0 !important;
}

.formio-errors > .error:nth-child(n + 2) {
    display: none;
}

.formio-component-editgrid {
    border: 1px solid #d5dbe1;
    border-radius: 5px;
    padding: 15px;
}

.editgrid-listgroup {
    padding: 10px 10px;
}

/* QuoteOrder - Details page */
.custom-oqDetails-companyDetailsSection,
.custom-patentDetails-companyDetailsSection {
    @apply p-3 border-b-[1.5px] rounded-t-xl bg-primary-50;

    label {
        @apply text-neutral-200;
    }
}

.custom-quote-details-section,
.custom-quote-details-section-2,
.custom-patent-details-section,
.custom-patent-details-section-2,
.custom-patent-details-section-3 {
    @apply p-3;
}

.patent-warning-label {
    @apply flex justify-start items-center gap-2 text-sm w-fit bg-orange-50 border-2 rounded-md border-orange-100 mb-3 px-3 py-1;
}

.patent-warning-label.before-warning-img::before {
    content: '\e989';
    font-family: 'PrimeIcons', sans-serif;
    font-size: 1em;
    color: #f59e0b;
    margin-right: 4px;
}

.multipleLanguageGrid {
    .editgrid-table-head tr:nth-child(1) {
        @apply !border-b-neutral-100;
    }

    .editgrid-table-head td:nth-child(3) {
        text-align: center;
        width: 10%;
    }

    .editgrid-table-body td:nth-child(3) {
        text-align: center;
        width: 10%;
    }
}

.custom-pop-header {
    @apply flex items-center justify-between bg-white drop-shadow p-4 px-6 rounded-t-lg text-xl font-medium text-primary-200;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
    @apply !relative bg-white w-10/12 md:w-5/12 shadow-lg rounded-md;

    .btn-primary {
        @apply float-right mr-3 mb-3 text-white;
    }

    .choices__list--dropdown .choices__list,
    .choices__list[aria-expanded] .choices__list {
        @apply fixed bg-white;
    }
}

.formio-dialog.formio-dialog-theme-default.component-settings {
    @apply !pt-36 !pb-7;

    .formio-dialog-content {
        @apply !p-0;
    }

    .formio-dialog-close:before {
        @apply absolute top-2 right-3;
    }
}

.custom-body-languageCombination {
    @apply p-3;
}

[ref='nested-languagecombination'] {
    .formio-component .form-control.ui.fluid.selection.dropdown {
        justify-content: center;
    }

    .choices__list.choices__list--multiple {
        overflow: scroll;
    }
}

/* custom.css */
[ref='confirmationDialog'] {
    padding: 1rem;
    /* Equivalent to p-4 in Tailwind */
}

[ref='confirmationDialog'] [ref='dialogHeader'] {
    font-size: 1.125rem;
    /* Equivalent to text-lg in Tailwind */
    font-weight: bold;
    /* Equivalent to font-bold in Tailwind */
    margin-bottom: 1rem;
    /* Equivalent to mb-4 in Tailwind */
}

[ref='confirmationDialog'] > div {
    display: flex;
    /* Equivalent to flex in Tailwind */
    justify-content: flex-end;
    /* Equivalent to justify-end in Tailwind */
    gap: 0.25rem;
    /* Equivalent to gap-1 in Tailwind */
    padding: 1.25rem;
    /* Equivalent to p-5 in Tailwind */
}

[ref='confirmationDialog'] [ref='dialogCancelButton'] {
    padding: 0.5rem 1rem;
    /* Equivalent to px-4 py-2 in Tailwind */
    background-color: #d1d5db;
    /* Equivalent to bg-gray-300 in Tailwind */
    color: #374151;
    /* Equivalent to text-gray-700 in Tailwind */
}

[ref='confirmationDialog'] [ref='dialogCancelButton']:hover {
    background-color: #9ca3af;
    /* Equivalent to hover:bg-gray-400 in Tailwind */
}

[ref='confirmationDialog'] [ref='dialogYesButton'] {
    padding: 0.5rem 1rem;
    /* Equivalent to px-4 py-2 in Tailwind */
    background-color: #ef4444;
    /* Equivalent to bg-red-500 in Tailwind */
    color: #ffffff;
    /* Equivalent to text-white in Tailwind */
}

[ref='confirmationDialog'] [ref='dialogYesButton']:hover {
    background-color: #dc2626;
    /* Equivalent to hover:bg-red-600 in Tailwind */
}

#vendor_rate_popup_modal .form-text.text-muted,
#minimum_charge_modal .form-text.text-muted {
    position: absolute;
    right: 0;
    top: 0;
    @apply bg-warning/40 w-fit px-2 rounded border border-warning text-neutral-700 flex gap-1 items-center;
}

#pv_rates_company_translations .form-text.text-muted {
    position: absolute;
    right: 10px;
    font-size: 9px;
    top: -2px;
    @apply bg-warning/40 w-fit px-1 rounded border border-warning text-neutral-700 flex gap-1 items-center;
}

@media (min-width: 1076px) {
    #pv_rates_company_translations .popup-wrapper {
        width: 47% !important;
    }
}

@media (min-width: 1160px) and (max-width: 1279px) {
    #pv_rates_company_translations .popup-wrapper {
        width: 55% !important;
    }
}

#pv_rates_vendor_translations .form-text.text-muted {
    position: absolute;
    right: 10px;
    top: 0;
    @apply bg-warning/40 w-fit px-2 rounded border border-warning text-neutral-700 flex gap-1 items-center;
}

#vendor_rate_popup_modal
    .form-group.has-feedback.formio-component.formio-component-select.formio-component-targetLanguage.formio-component-multiple,
#vendor_rate_popup_modal
    .form-group.has-feedback.formio-component.formio-component-select.formio-component-targetLanguage2.formio-component-multiple,
#minimum_charge_modal
    .form-group.has-feedback.formio-component.formio-component-select.formio-component-multiple.formio-component-targetLanguageId,
#minimum_charge_modal
    .form-group.has-feedback.formio-component.formio-component-select.formio-component-multiple.formio-component-targetLanguageId1 {
    position: relative;
}

.feedback-form {
    .choices__list--dropdown .choices__list,
    .choices__list[aria-expanded] .choices__list {
        max-height: 176px !important;
    }
    .choices__list--dropdown .choices__list {
        #choices--e9x2uqw-selectedPage-item-choice-1 {
            font-size: 14px !important;
            padding-bottom: 6px !important;
        }
    }
}

.registration-phonecode-wrapper > div:has(.registration-phonecode) {
    padding-right: 0px;
}
.registration-phonecode-wrapper .registration-phonecode .form-control.dropdown {
    padding: 0px 5px;
}

.additionalInfoStepper .ccComponent {
    @apply relative;
}

.additionalInfoStepper .ccComponent .form-text:not(.error) {
    @apply absolute top-0 right-0 !important;
}

.formalDocumentUpload {
    .choices__list--dropdown .choices__list,
    .choices__list[aria-expanded] .choices__list {
        max-height: 106px !important;
    }
}

.formio-component-columnstaxId,
.formio-component-columnsBankName,
.formio-component-columnsBankAccNo,
.formio-component-columnsRouterNo {
    div:nth-child(1) {
        @apply pr-0;
    }
    div:nth-child(2) {
        @apply pl-0;
    }
}

.formio-component-eyeKey,
.formio-component-eyeBankName,
.formio-component-eyeBankAccountNo,
.formio-component-eyeRouterNo {
    @apply mt-4;
    button {
        @apply gap-0 bg-white border-0 shadow-none;
    }
}
.formio-component.pi::before {
    display: none;
}

.patentDetailsPage {
    .formio-component-applicantName {
        @apply w-[32%];
    }
}

.formio-component-textarea:has(.ql-container.ql-disabled) .ql-toolbar {
    display: none;
}

.formio-component-textarea .ql-container {
    margin-bottom: 10px;
    min-height: 70px;
    max-height: 250px;
    overflow: auto;
}

.formio-component-textarea {
    .ql-toolbar.ql-snow {
        @apply rounded-t-md;
    }
    .ql-container.ql-snow {
        @apply rounded-b-md;
    }
}
